<template>
   <div class="row align-items-start">
      <div class="nav flex-sm-column nav-pills mx-auto px-sm-0 mb-1 mb-sm-0 justify-content-center" id="infoconta-tab" role="tablist" aria-orientation="vertical">
			<button class="nav-link mb-1 active" id="conta-tab" data-bs-toggle="pill" data-bs-target="#conta" type="button" role="tab" aria-controls="conta" aria-selected="true">
				<span class="d-flex align-items-center">
					<i class="fal fa-home mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Conta</span>
				</span>
			</button>
			<button class="nav-link mb-1" id="modulos-tab" data-bs-toggle="pill" data-bs-target="#modulos" type="button" role="tab" aria-controls="modulos" aria-selected="false">
				<span class="d-flex align-items-center">
					<i class="fal fa-lock mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Módulos</span>
				</span>
			</button>
			<button class="nav-link mb-1" id="lojas-tab" data-bs-toggle="pill" data-bs-target="#lojas" type="button" role="tab" aria-controls="lojas" aria-selected="false">
				<span class="d-flex align-items-center">
					<i class="fal fa-store mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Lojas</span>
				</span>
			</button>
			<button class="nav-link mb-1" id="usuarios-tab" data-bs-toggle="pill" data-bs-target="#usuarios" type="button" role="tab" aria-controls="usuarios" aria-selected="false">
				<span class="d-flex align-items-center">
					<i class="fal fa-users mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Usuários</span>
				</span>
			</button>
			<button class="nav-link mb-1" id="faturas-tab" data-bs-toggle="pill" data-bs-target="#faturas" type="button" role="tab" aria-controls="faturas" aria-selected="false">
				<span class="d-flex align-items-center">
					<i class="fal fa-barcode mx-auto ms-xl-0 me-xl-3"></i><span class="d-none d-xl-block">Faturas</span>
				</span>
			</button>
		</div>

		<div class="tab-content px-sm-0" id="infoconta-tabContent">
			<div class="tab-pane fade show active" id="conta" role="tabpanel" aria-labelledby="conta-tab">
				<div class="row">
					<usuario class="px-1" :usuario="pesquisa.usuario" :index="-1" @editar="abrirModalUsuario($event)" :modulo="pesquisa.modulo" />

					<div class="col-12 mb-1">
						<div class="card">
							<div class="card-body px-12 pt-12 pb-0">
								<div class="row">
									<div class="col-12 mb-3">
										<h6 class="mb-0 mt-1 font-15"><i class="far fa-user color-theme font-13 me-2"></i>Dados da conta</h6>
									</div>
									<div class="col-6 col-lg-4 mb-3">
										<label><i class="far fa-puzzle-piece color-theme font-11 me-1"></i> Módulo *</label>
										<v-select name="Desktop" :options="['VAREJO', 'RESTAURANTES']" v-model="pesquisa.modulo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" :disabled="pesquisa.id != null" />
										<select name="Mobile" class="form-control" v-model="pesquisa.modulo" :disabled="pesquisa.id">
											<option v-for="(option, index) in ['VAREJO', 'RESTAURANTES']" :key="index" :value="option">{{ option }}</option>
										</select>
									</div>
									<div class="col-6 col-lg-4 mb-3">
										<label><i class="far fa-stream color-theme font-11 me-1"></i> Tipo empresa *</label>
										<v-select name="Desktop" :options="['Lucro real', 'Simples nacional']" v-model="pesquisa.tipoEmpresa" :clearable="false" :searchable="false" placeholder="Selecione uma opção" @input="salvarConta" />
										<select name="Mobile" class="form-control" v-model="pesquisa.tipoEmpresa" @change="salvarConta">
											<option v-for="(option, index) in ['Lucro real', 'Simples nacional']" :key="index" :value="option">{{ option }}</option>
										</select>
									</div>
									<div class="col-6 col-lg-4 mb-3" v-if="pesquisa.modulo == 'VAREJO'">
										<label><i class="far fa-wifi-slash color-theme font-11 me-1"></i> Caixas Offline *</label>
										<input type="text" class="form-control" v-model="pesquisa.qtdPdvsOffline" maxlength="200" @blur="salvarConta" />
									</div>
									<div class="col-6 col-lg-4 mb-3" v-if="pesquisa.modulo == 'VAREJO'">
										<label><i class="far fa-wifi color-theme font-11 me-1"></i> Caixas Online *</label>
										<input type="text" class="form-control" v-model="pesquisa.qtdPdvsOnline" maxlength="200" @blur="salvarConta" />
									</div>
									<div class="col-6 col-lg-4 mb-3" v-if="pesquisa.modulo == 'RESTAURANTES'">
										<label><i class="far fa-utensils color-theme font-11 me-1"></i> Mesas *</label>
										<input type="text" class="form-control" v-model="pesquisa.qtdMesas" maxlength="200" @blur="salvarConta" />
									</div>
									<div class="col-6 col-lg-4 mb-3">
										<label><i class="far fa-store color-theme font-11 me-1"></i> Lojas *</label>
										<input type="text" class="form-control" v-model="pesquisa.qtdLojas" maxlength="200" @blur="salvarConta" />
									</div>
									<div class="col-6 col-lg-4 mb-3">
										<label><i class="far fa-users color-theme font-11 me-1"></i> Usuários *</label>
										<input type="text" class="form-control" v-model="pesquisa.qtdUsuarios" maxlength="200" @blur="salvarConta" />
									</div>
									<div class="col-6 col-lg-4 mb-3">
										<label><i class="far fa-money-bill-wave-alt color-theme font-11 me-1"></i> Valor Crédito *</label>
										<input type="text" class="form-control" v-model="pesquisa.credito" @blur="salvarConta" />
									</div>
									<div class="col-6 col-lg-4 mb-3">
										<label><i class="far fa-calendar-day color-theme font-11 me-1"></i> Dias vencimento *</label>
										<input type="text" class="form-control" v-model="pesquisa.diasVencimento" @blur="salvarConta" />
									</div>
									<div class="col-6 col-lg-4 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Limite teste</label>
										<input type="text" class="form-control" v-model="pesquisa.dataLimiteTeste" v-mask="'##/##/####'" @blur="salvarConta" />
									</div>
									<div class="col-6 col-lg-4 mb-3">
										<label><i class="far fa-dollar-sign color-theme font-11 me-1"></i> Valor fatura *</label>
										<input type="text" class="form-control" v-model="pesquisa.valorFatura" @blur="salvarConta" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12">
						<div class="cards-275">
							<div class="card">
								<div class="card-body px-12">
									<div class="row align-items-center">
										<div class="wpx-100 text-center">
											<i class="far fa-dollar-sign color-theme font-24 d-block mb-2"></i><strong class="weight-500">Financeiro</strong>
										</div>
										<div class="col text-center">
											<span class="limitador-1 font-20">F{{ pesquisa.indicadores.Financeiro }}</span>
											<span class="limitador-1 font-10">{{ textos.financeiro[pesquisa.indicadores.Financeiro] }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-body px-12">
									<div class="row align-items-center">
										<div class="wpx-100 text-center">
											<i class="far fa-tools color-theme font-24 d-block mb-2"></i><strong class="weight-500">Técnico</strong>
										</div>
										<div class="col text-center">
											<span class="limitador-1 font-20">T{{ pesquisa.indicadores.Tecnico }}</span>
											<span class="limitador-1 font-10">{{ textos.financeiro[pesquisa.indicadores.Tecnico] }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-body px-12">
									<div class="row align-items-center">
										<div class="wpx-100 text-center">
											<i class="far fa-tasks color-theme font-24 d-block mb-2"></i><strong class="weight-500">Operacional</strong>
										</div>
										<div class="col text-center">
											<span class="limitador-1 font-20">O{{ pesquisa.indicadores.Operacional }}</span>
											<span class="limitador-1 font-10">{{ textos.financeiro[pesquisa.indicadores.Operacional] }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-body px-12">
									<div class="row align-items-center">
										<div class="wpx-100 text-center">
											<i class="far fa-calendar color-theme font-24 d-block mb-2"></i><strong class="weight-500">Calendário</strong>
										</div>
										<div class="col text-center">
											<span class="limitador-1 font-20">C{{ pesquisa.indicadores.Calendario }}</span>
											<span class="limitador-1 font-10">{{ textos.financeiro[pesquisa.indicadores.Calendario] }}</span>
										</div>
									</div>
								</div>
							</div>
							<div class="card">
								<div class="card-body px-12">
									<div class="row align-items-center">
										<div class="wpx-100 text-center">
											<i class="far fa-microchip color-theme font-24 d-block mb-2"></i><strong class="weight-500">Tecnologia</strong>
										</div>
										<div class="col text-center">
											<span class="limitador-1 font-20">Tec{{ pesquisa.indicadores.Tecnologia }}</span>
											<span class="limitador-1 font-10">{{ textos.financeiro[pesquisa.indicadores.Tecnologia] }}</span>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="modulos" role="tabpanel" aria-labelledby="modulos-tab">
				<div class="row">
					<div class="col-12 mb-1" v-if="pesquisa.modulos.length == 0">
						<div class="card mb-0">
							<div class="card-body py-5 text-center">
								<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhum módulo encontrado</h4>
							</div>
						</div>
					</div>

					<modulo v-for="(modulo, index) in pesquisa.modulos" :key="index" :index="index" :modulo="modulo" @toggle="toggleModulo($event)" />
				</div>
			</div>
			<div class="tab-pane fade" id="lojas" role="tabpanel" aria-labelledby="lojas-tab">
				<div class="row">
					<div class="col-12 mb-1" v-if="pesquisa.lojas.length == 0">
						<div class="card mb-0">
							<div class="card-body py-5 text-center">
								<i class="fal fa-store-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma loja encontrada</h4>
							</div>
						</div>
					</div>

					<loja v-for="(loja, index) in pesquisa.lojas" :key="index" :loja="loja" :index="index" />
				</div>
			</div>
			<div class="tab-pane fade" id="usuarios" role="tabpanel" aria-labelledby="usuarios-tab">
				<div class="row">
					<div id="search-nav" class="col-12 d-block btn-group dropup">
						<button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
							<i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
						</button>
						<div class="card mb-2 dropdown-menu dropdown-menu-left">
							<div class="card-body pb-0">
								<div class="row">
									<div class="col-md mb-3">
										<label><i class="far fa-user color-theme font-11 me-1"></i> Nome/CPF/RG</label>
										<input type="text" class="form-control" v-model="pesquisaUsuario.nome" maxlength="200" v-focus @keyup.enter="buscarUsuarios" placeholder="Busque por nome do usuário, CPF ou RG" />
									</div>
									<div class="col-md-4 col-xl-3 px-0 mb-3 align-self-end">
										<div class="row">
											<div class="col">
												<button type="button" class="btn btn-primary w-100" @click="buscarUsuarios"><i class="fas fa-search font-13"></i></button>
											</div>
											<div class="col-8">
												<button type="button" class="btn btn-primary w-100" @click="abrirModalUsuario(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-12" v-if="pesquisaUsuario.resultado.length > 0">
						<div class="card mb-0">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-2 align-self-center">
										<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisaUsuario.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisaUsuario.resultado.length }} resultado(s)</div>
									</div>

									<usuario v-for="(usuario, index) in pesquisaUsuario.resultado" :key="index" :usuario="usuario" :index="index" :modulo="pesquisa.modulo" 
										@editar="abrirModalUsuario($event)" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="faturas" role="tabpanel" aria-labelledby="faturas-tab">
				<div class="row">
					<div class="col-12 mb-1">
						<div class="card mb-0">
							<div class="card-body">
								<h6 class="mb-0 font-14 weight-400">
									<span>{{ pesquisa.faturas.length }} fatura(s) encontrada(s)</span>
									<a href="javascript:;" class="float-end font-16" @click="abrirModalFatura(null)"><i class="fas fa-plus color-theme"></i></a>
								</h6>
							</div>
						</div>
					</div>
					<div class="col-12 mb-1" v-if="pesquisa.faturas.length == 0">
						<div class="card mb-0">
							<div class="card-body py-5 text-center">
								<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
								<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Nenhuma fatura encontrada</h4>
							</div>
						</div>
					</div>

					<fatura v-for="(fatura, index) in pesquisa.faturas" :key="index" :fatura="fatura" :index="index" @editar="abrirModalFatura($event)" />
				</div>
			</div>
		</div>

		<!-- modalUsuario -->
      <div class="modal fade" id="modalUsuario" tabindex="-1" aria-labelledby="modalUsuarioLabel" aria-hidden="true">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalUsuarioLabel">{{ modalUsuario.id == null ? 'Adicionar' : 'Editar' }} usuário {{ modalUsuario.id == null ? '' : ('('+ modalUsuario.nomeCompleto +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="accordion" id="accordionExample">
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingOne">
									<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										<i class="fal fa-home"></i> Informações básicas
									</button>
								</h2>
								<div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-user color-theme font-12 me-1"></i> Nome *</label>
												<input type="text" class="form-control" v-model="modalUsuario.nomeCompleto" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-envelope color-theme font-11 me-1"></i> Email *</label>
												<input type="text" class="form-control" v-model="modalUsuario.email" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-address-card color-theme font-11 me-1"></i> CPF *</label>
												<input type="text" class="form-control" v-model="modalUsuario.cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-address-card color-theme font-11 me-1"></i> RG</label>
												<input type="text" class="form-control" v-model="modalUsuario.rg" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3 mb-sm-0">
												<label><i class="far fa-calendar color-theme font-11 me-1"></i> Data nascimento</label>
												<input type="text" class="form-control" v-model="modalUsuario.dataNascimento" v-mask="'##/##/####'" />
											</div>
											<div class="col-sm-6">
												<label><i class="far fa-lock color-theme font-11 me-1"></i> Senha de acesso</label>
												<input type="text" class="form-control key" v-model="modalUsuario.senha" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingTwo">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
										<i class="fal fa-map-marker-alt me-3"></i>Endereço
									</button>
								</h2>
								<div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-4 mb-3">
												<label><i class="far fa-location-arrow color-theme font-11 me-1"></i> CEP</label>
												<div class="input-group">
													<input type="text" class="form-control" v-model="modalUsuario.endereco.cep" v-mask="'##########'" @keyup.enter="buscarCEP" />
													<a href="javascript:;" class="input-group-text font-13" @click="buscarCEP">
														<i class="fas fa-search"></i>
													</a>
												</div>
											</div>
											<div class="col-sm-8 mb-3">
												<label><i class="far fa-home color-theme font-11 me-1"></i> Rua</label>
												<input type="text" class="form-control" v-model="modalUsuario.endereco.rua" maxlength="200" />
											</div>
											<div class="col-sm-4 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
												<input type="text" class="form-control" v-model="modalUsuario.endereco.numero" maxlength="200" />
											</div>
											<div class="col-sm-8 mb-3">
												<label><i class="far fa-road color-theme font-11 me-1"></i> Bairro</label>
												<input type="text" class="form-control" v-model="modalUsuario.endereco.bairro" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cidade</label>
												<input type="text" class="form-control" v-model="modalUsuario.endereco.cidade" maxlength="200" />
											</div>
											<div class="col-sm-6 mb-3">
												<label><i class="far fa-map-marker-alt color-theme font-11 me-1"></i> Estado</label>
												<v-select name="Desktop" :options="estados" v-model="modalUsuario.endereco.estado" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
												<select name="Mobile" class="form-control" v-model="modalUsuario.endereco.estado">
													<option v-for="(option, index) in estados" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-sm-4 mb-3 mb-sm-0">
												<label><i class="far fa-map-signs color-theme font-11 me-1"></i> Cód. Município</label>
												<input type="text" class="form-control" v-model="modalUsuario.endereco.codMunicipio" maxlength="200" />
											</div>
											<div class="col-sm-8">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Complemento</label>
												<input type="text" class="form-control" v-model="modalUsuario.endereco.complemento" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="accordion-item">
								<h2 class="accordion-header" id="headingThree">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
										<i class="fal fa-phone-alt"></i> Telefone
									</button>
								</h2>
								<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
									<div class="accordion-body">
										<div class="row">
											<div class="col-sm-3 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> DDD</label>
												<input type="text" class="form-control" v-model="modalUsuario.telefone.ddd" v-mask="['(##)', '(###)']" />
											</div>
											<div class="col-sm-5 mb-3">
												<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Número</label>
												<input type="text" class="form-control" v-model="modalUsuario.telefone.telefone" v-mask="['####-####', '#####-####']" />
											</div>
											<div class="col-sm-4 mb-3 mb-sm-0">
												<label><i class="far fa-tag color-theme font-11 me-1"></i> Tipo</label>
												<v-select name="Desktop" :options="['Fixo', 'Celular']" v-model="modalUsuario.telefone.tipo" :clearable="false" :searchable="false" placeholder="Selecione uma opção" />
												<select name="Mobile" class="form-control" v-model="modalUsuario.telefone.tipo">
													<option v-for="(option, index) in ['Fixo', 'Celular']" :key="index" :value="option">{{ option }}</option>
												</select>
											</div>
											<div class="col-12">
												<label><i class="far fa-user-alt color-theme font-11 me-1"></i> Nome contato</label>
												<input type="text" class="form-control" v-model="modalUsuario.telefone.nomeContato" maxlength="200" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarUsuario">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalFatura -->
		<div class="modal fade" id="modalFatura" tabindex="-1" aria-labelledby="modalFaturaLabel" aria-hidden="true">
			<div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="modalFaturaLabel">{{ modalFatura.id == null ? 'Adicionar' : 'Editar' }} fatura {{ modalFatura.id == null ? '' : ('(Venc. '+ modalFatura.vencimento +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">					
                  <div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-6 mb-3">
										<label><i class="far fa-money-bill-wave-alt color-theme font-11 me-1"></i> Valor</label>
										<input type="text" class="form-control" v-model="modalFatura.total" maxlength="20">
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Vencimento</label>
										<input type="text" class="form-control" v-model="modalFatura.vencimento" v-mask="'##/##/####'" />
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Início</label>
										<input type="text" class="form-control" v-model="modalFatura.inicio" v-mask="'##/##/####'" />
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-11 me-1"></i> Fim</label>
										<input type="text" class="form-control" v-model="modalFatura.fim" v-mask="'##/##/####'" />
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-info-circle color-theme font-11 me-1"></i> Observações</label>
										<input type="text" class="form-control" v-model="modalFatura.observacao" />
									</div>
									<div class="col-6 mb-3 align-self-end">
										<button class="btn w-100" :class="modalFatura.pago ? 'btn-success' : 'btn-danger'" @click="modalFatura.pago = !modalFatura.pago">
											<i class="far font-13 me-1" :class="modalFatura.pago ? 'fa-check' : 'fa-clock'"></i> {{ modalFatura.pago ? 'Pago' : 'Pendente' }}
										</button>
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarFatura">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import loja from '@/components/contas/Loja.vue'
import fatura from '@/components/contas/Fatura.vue'
import usuario from '@/components/contas/Usuario.vue'
import modulo from '@/components/contas/CheckModulo.vue'

export default {
   name: 'Info',
	data: function () {
		return {
			pesquisa: {
				'faturas': [], 'id': null, 'inicio': null, 'fim': null, 'qtdPdvsOnline': 1, 'qtdPdvsOffline': 0, 'qtdLojas': 1, 'qtdUsuarios': 1, 'tipoEmpresa': 'Simples nacional', 
				'valorFatura': '0.00', 'credito': '0.00', 'diasVencimento': 10, 'dataLimiteTeste': null, 'pagamentoPendente': false, 'lojas': [], 'modulos': [], 'indicadores': {}, 
				'usuario': {}, 'modulo': null, 'qtdMesas': null
			},
			pesquisaUsuario: {'nome': '', 'idConta': null, 'resultado': []},
			modalUsuario: {
				'id': null, 'idConta': null, 'cpf': null, 'rg': null, 'dataNascimento': null, 'email': null, 'nomeCompleto': null, 'senha': null, 
				'endereco': {'id': null, 'bairro': '', 'cep': '', 'cidade': '', 'codMunicipio': '', 'complemento': '', 'estado': '', 'numero': '', 'pais': '', 'rua': ''}, 
				'telefone': {'id': null, 'ddd': '', 'nomeContato': '', 'telefone': '', 'tipo': 'Fixo'}
			},
			modalFatura: {'id': null, 'idConta': null, 'inicio': new Date(), 'fim': new Date(), 'vencimento': new Date(), 'total': '0.00', 'pago': false, 'observacao': null},
			textos: {'financeiro': ['Sem avaliação', 'Inadimplente a -MM', 'Atrasado', 'Não possue débitos', 'Não possue débitos a -MM', 'Não possue débitos a -A'], 'tecnico': ['Sem avaliação', 'Não vai conseguir', 'Vai conseguir com dificuldade', 'Vai conseguir usar normal', 'Vai usar com facilidade', 'Vai usar e ser modelo para outras empresas'], 'operacional': ['Sem avaliação', 'Inexistente', 'Usa bem pouco', 'Normal', 'Usa todos os dias', 'Empresa totalmente dependente'], 'calendario': ['Sem avaliação', 'Não consegue contato', 'Consegue contato, mas não consegue marcar', 'Marcou e não compareceu', 'Marcou e compareceu', 'Facilidade em marcar e realizar reunião/atendimento'], 'tecnologia': ['Sem avaliação', 'Inexistente', 'Mínimo necessário, tem de melhorar', 'Vai rodar', 'Rodar com bom desempenho', 'Rodar sobrando desempenho']}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
         urlRest: state => state.urlRest,
			estados: state => state.estados,
         tabs: state => state.tabs
      })
   },
	watch: {
		'$route.params.idTab': function () {
         this.buscarConta()
      },
		'modalFatura.total' : function (newVal) {
			this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
				this.modalFatura.total = valor
			})
		},
		'pesquisa.credito' : function (newVal) {
			this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
				this.pesquisa.credito = valor
			})
		},
		'pesquisa.valorFatura' : function (newVal) {
			this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
				this.pesquisa.valorFatura = valor
			})
		},
		'pesquisa.modulos': {
			handler: function (newValue, oldValue) {
				if (oldValue != null && oldValue.length != 0) {
					this.salvarModulos()
				}
			},
			deep: true
		}
	},
	components: {
		loja, usuario, fatura, modulo
	},
	methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		buscarConta : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getConta',
				params: {
					id: this.$route.query.idConta
				}
			}).then(response => {
				this.pesquisa = response.data
				this.pesquisa.dataLimiteTeste = (this.pesquisa.dataLimiteTeste == null ? null : String(this.pesquisa.dataLimiteTeste.dayOfMonth).padStart(2, '0') +'/'+ String(this.pesquisa.dataLimiteTeste.monthValue).padStart(2, '0') +'/'+ this.pesquisa.dataLimiteTeste.year)
				this.pesquisa.credito = (this.pesquisa.credito == null ? '0.00' : parseFloat(this.pesquisa.credito).toFixed(2))
				this.pesquisa.valorFatura = (this.pesquisa.valorFatura == null ? '0.00' : parseFloat(this.pesquisa.valorFatura).toFixed(2))
				
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		buscarUsuarios : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisaUsuario.resultado, this.$options.data.apply(this).pesquisaUsuario.resultado)
			this.pesquisaUsuario.idConta = this.pesquisa.id

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/searchUsuarios',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisaUsuario,
				params: {
					modulo: this.pesquisa.modulo
				}
			}).then(response => {
				this.pesquisaUsuario.resultado = response.data.resultado

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		abrirModalUsuario : function (usuario) {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.modalUsuario, this.$options.data.apply(this).modalUsuario)
			
			this.$axios({
				method: 'get',
				url: this.urlRest +'configuracoesAdmin/getUsuario',
				params: {
					modulo: this.pesquisa.modulo,
					email: usuario == null ? null : usuario.email
				}
			}).then(response => {
				$('#modalUsuario').modal('show')
				
				this.modalUsuario = {
					'id': response.data.id, 
					'idConta': this.pesquisa.id, 
					'cpf': response.data.cpf, 
					'rg': response.data.rg, 
					'dataNascimento': response.data.dataNascimento == null ? null : String(response.data.dataNascimento.dayOfMonth).padStart(2, '0') +'/'+ String(response.data.dataNascimento.monthValue).padStart(2, '0') +'/'+ response.data.dataNascimento.year, 
					'email': response.data.email, 
					'nomeCompleto': response.data.nomeCompleto, 
					'senha': response.data.senha, 
					'codigoSupervisor': response.data.codigoSupervisor, 
					'endereco': response.data.endereco, 
					'telefone': response.data.telefone
				}
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		abrirModalFatura : function (fatura) {
			$('#modalFatura').modal('show')

			if (fatura) {
				let obj = JSON.parse(JSON.stringify(fatura))
					
				this.modalFatura = {
					'id': obj.id, 
					'idConta': obj.idConta, 
					'inicio': obj.inicio == null ? null : String(obj.inicio.dayOfMonth).padStart(2, '0') +'/'+ String(obj.inicio.monthValue).padStart(2, '0') +'/'+ obj.inicio.year, 
					'fim': obj.fim == null ? null : String(obj.fim.dayOfMonth).padStart(2, '0') +'/'+ String(obj.fim.monthValue).padStart(2, '0') +'/'+ obj.fim.year, 
					'vencimento': obj.vencimento == null ? null : String(obj.vencimento.dayOfMonth).padStart(2, '0') +'/'+ String(obj.vencimento.monthValue).padStart(2, '0') +'/'+ obj.vencimento.year, 
					'total': obj.total == null ? '0.00' : parseFloat(obj.total).toFixed(2), 
					'pago': obj.pago, 
					'observacao': obj.observacao
				}
			} else {
				this.modalFatura = {'id': null, 'idConta': this.pesquisa.id, 'inicio': '', 'fim': '', 'vencimento': '', 'total': '0.00', 'pago': false, 'observacao': ''}
			}
		},
		toggleModulo : function (idModulo) {
         this.pesquisa.modulos.forEach(modulo => {
            if (modulo.id == idModulo) {
               modulo.habilitado = !modulo.habilitado

               modulo.roles.forEach(role => {
                  role.habilitado = modulo.habilitado
               });
            }
         });
      },
		buscarCEP : function () {
         if (String(this.modalUsuario.endereco.cep).trim().length < 8) {
            return;
         }

         this.$store.dispatch('buscarCEP', this.modalUsuario.endereco.cep).then((data) => {
				this.modalUsuario.endereco.rua = data.logradouro
				this.modalUsuario.endereco.complemento = data.complemento
				this.modalUsuario.endereco.bairro = data.bairro
				this.modalUsuario.endereco.cidade = data.localidade
				this.modalUsuario.endereco.estado = data.uf
				this.modalUsuario.endereco.codMunicipio = data.ibge
			})
      },
		salvarUsuario : function () {
			if (this.modalUsuario.cpf == null || String(this.modalUsuario.cpf).trim().length == 0 || this.modalUsuario.nomeCompleto == null || 
				String(this.modalUsuario.nomeCompleto).trim().length == 0 || this.modalUsuario.email == null || String(this.modalUsuario.email).trim().length == 0) {

				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveUsuario',
				headers: {'Content-Type': 'application/json'},
				data: this.modalUsuario,
				params: {
					modulo: this.pesquisa.modulo
				}
			}).then(() => {
				$('#modalUsuario').modal('hide')
				this.buscarUsuarios()
				
				this.$toast.fire({
					icon: 'success',
					title: 'Usuário salvo!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Email/CPF já cadastrado!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarFatura : function () {
			if (this.modalFatura.total == null || String(this.modalFatura.total).trim().length == 0 || parseFloat(this.modalFatura.total) <= 0 || 
				this.modalFatura.vencimento == null || this.modalFatura.inicio == null || this.modalFatura.fim == null) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveFatura',
				headers: {'Content-Type': 'application/json'},
				data: this.modalFatura

			}).then(() => {
				$('#modalFatura').modal('hide')
				this.buscarConta()

				this.$toast.fire({
					icon: 'success',
					title: 'Fatura salva!'
				});
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else if (error.response.status == 400) {
						this.$toast.fire({
							icon: 'error',
							title: 'Limite de usuários excedido!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				} else {
					this.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		salvarModulos : function () {
			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveModulos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa.modulos,
				params: {
					idConta: this.pesquisa.id
				}
			});
		},
		salvarConta : function () {
			if (this.pesquisa.tipoEmpresa == null || this.pesquisa.qtdLojas == null || String(this.pesquisa.qtdLojas).trim().length == 0 || this.pesquisa.qtdUsuarios == null || 
				String(this.pesquisa.qtdUsuarios).trim().length == 0 || this.pesquisa.credito == null || 
				String(this.pesquisa.credito).trim().length == 0 || this.pesquisa.diasVencimento == null || String(this.pesquisa.diasVencimento).trim().length == 0 ||
				this.pesquisa.valorFatura == null || String(this.pesquisa.valorFatura).trim().length == 0) return;

			if (this.pesquisa.modulo == 'VAREJO' && (this.pesquisa.qtdPdvsOnline == null || String(this.pesquisa.qtdPdvsOnline).trim().length == 0 || 
				this.pesquisa.qtdPdvsOffline == null || String(this.pesquisa.qtdPdvsOffline).trim().length == 0)) return;

			if (this.pesquisa.modulo == 'RESTAURANTES' && (this.pesquisa.qtdMesas == null || String(this.pesquisa.qtdMesas).trim().length == 0)) return;

			this.$axios({
				method: 'post',
				url: this.urlRest +'configuracoesAdmin/saveConta',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa
			});
		}
	},
	mounted() {
		this.buscarConta()
	}
}

</script>

<style scoped>

#infoconta-tab .nav-link i {
   font-size: 18px;
   width: 21px;
   text-align: center;
}

#infoconta-tab .nav-link {
   line-height: 1;
   padding: 14px 20px;
   text-align: start;
   font-weight: 500;
   text-transform: uppercase;
   font-size: 12px;
	width: auto;
	flex: 1 0 0%;
   transition: none !important;
}

#infoconta-tab .nav-link * {
   transition: none !important;
}

#infoconta-tab,
#infoconta-tabContent {
   width: 100%;
}

@media (min-width: 576px) {
   #infoconta-tabContent {
      min-height: 210px;
      width: calc(100% - 85px)
   }
   
   #infoconta-tab {
      width: 85px;
   }
}

@media (min-width: 1200px) {
   #infoconta-tabContent {
      min-height: 210px;
      width: calc(100% - 210px)
   }

   #infoconta-tab {
      width: 210px;
   }
}

</style>